<template>
  <div>
    <br>
    <a href="#" @click.prevent="open_finder2()">
      <div class="upload-file-content" id="eldropzone" >
        <div class="upload-file">Click para subir más archivos </div>
      </div>
    </a>
    <input
      id="files2"
      type="file"
      multiple="multiple"
      style="visibility: hidden;"
      @change="get_files()"
    >
  </div>
</template>

<script>

export default {
  name: 'UploadMulty',
  created() {
    this.add_event_drop_ele();
  },
  data() {
    return {
      base: process.env.VUE_APP_BASE_URL,
      instance: {},
    };
  },
  props: {
    apply_class: {
      type: String,
      default: null,
    },
    avatar_url: {
      type: String,
      default: null,
    },
    avatar_id: {
      type: String,
      default: null,
    },
    image_previo: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    validar_archivos(files) {
      const tipos = ['video/x-msvideo', 'video/mp4'];
      /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
      for (let file = 0; file < files.length; file++) {
        if (files[file].size === 0) {
          this.$swal.fire('Error!', `El archivo  ${files[file].name}  esta vacio`, 'error');
          return false;
        }
        if (files[file].size > 20000000) {
          this.$swal.fire('Error!', `El archivo ${files[file].name} supera el límite permitido`, 'error');
          return false;
        }
        if (tipos.includes(files[file].type)) {
          this.$swal.fire('Error!', `El archivo ${files[file].name} tiene un formato no permitido.`, 'error');
          return false;
        }
        const imageType = /video.*/;
        if (files[file].type.match(imageType)) {
          this.$swal.fire('Error!', `El archivo ${files[file].name} tiene un formato no permitido.`, 'error');
          return false;
        }
      }
      return true;
    },
    async add_event_drop_ele() {
      this.$nextTick(() => {
        const dropZoneDiv = document.querySelector('#eldropzone');

        dropZoneDiv.ondragover = (event) => {
          dropZoneDiv.classList.add('dropzone-hover');
          event.stopPropagation();
          event.preventDefault();
          /* eslint no-param-reassign: [2, { "props": false }] */
          event.dataTransfer.dropEffect = 'copy';
        };
        dropZoneDiv.ondragleave = () => {
          dropZoneDiv.classList.remove('dropzone-hover');
        };
        // Handle file drop:
        dropZoneDiv.ondrop = async (ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          dropZoneDiv.classList.remove('dropzone-hover');
          const { files } = ev.dataTransfer;
          this.SeletedFile2(files);
        };
      });
      // Configure dropZoneDiv
    },
    emit_data2() {
      this.$emit('get_id_document', this.instance);
    },
    async postFileRequest(payload) {
      const data = await this.$http.post(
        'pictures/',
        payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    },
    async putFileRequest(payload) {
      const data = await this.$http.put(
        `pictures/${this.avatar_id}/`,
        payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    },
    get_files() {
      const docs = document.querySelector('#files2');
      this.SeletedFile2(docs.files);
    },
    SeletedFile2(docs) {
      const result = this.validar_archivos(docs);
      if (!result) return;

      const formData = new FormData();
      // const docs = document.querySelector('#files2');
      for (let file = 0; file < docs.length; file += 1) {
        formData.append('url', docs[file]);
        if (this.avatar_id) {
          formData.append('id', this.avatar_id);
          this.update_logo(formData);
          return;
        }
        this.postFileRequest(formData).then((data) => {
          this.instance = data.data;
          if (file + 1 === docs.length) {
            this.$set(this.instance, 'finalizado', true);
          }
          this.emit_data2();
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    open_finder2() {
      document.querySelector('#files2').click();
    },
    update_logo(payload) {
      const self = this;
      this.putFileRequest(payload).then((data) => {
        self.instance.id = data.data.id;
        self.emit_data2();
      }).catch((error) => {
        console.log(error);
      });
    },
    create_logo2(payload) {
      const self = this;
      this.postFileRequest(payload).then((data) => {
        self.instance = data.data;
        self.emit_data2();
      }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>
<style scope>
  .null {
    text-decoration: line-through !important;
  }
  .upload-file {
    border-style: dotted;
    border-style-color: red !important;
  }
  .dropzone {
    border: 2px dotted #bbb;
    border-radius: 10px;
    padding: 5px;
    color: #bbb;
    text-align: center;
    height: auto;
  }
  .dropzone-hover {
    border: 4px dotted #bbb !important;
    color: #bbb0;
  }
  @media screen(max-width: 900px) {
    .dropzone {
      padding: 10px !important;
    }
  }
  @media screen(max-width: 600px) {
    .dropzone {
      padding: 10px !important;
      font-size: 10px;
    }
  }
</style>
